import { recruiterTheme } from './recruiter.theme'
import { jtpTheme } from './jtp.theme'
import { jnpTheme } from './jnp.theme'
import { opusTheme } from './ops.theme'

export const THEMES = {
	recruiter: recruiterTheme,
	jtp: jtpTheme,
	jnp: jnpTheme,
	opus: opusTheme,
}
