import React from 'react'
import ReactDOM from 'react-dom'
import { App } from './app'
import { HttpsRedirect } from './components/route'
import * as serviceWorker from './serviceWorker'
import { Meta } from './components/meta/meta.component'
ReactDOM.render(
	<React.StrictMode>
		<Meta />
		<HttpsRedirect>
			<App />
		</HttpsRedirect>
	</React.StrictMode>,
	document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
