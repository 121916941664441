import { gql } from '@apollo/client'
import {
	EMERGENCY_CONTACT_FRAGMENT,
	IMAGE_FILE_FRAGMENT,
	JOB_APPLICANT_FRAGMENT,
	JOB_FRAGMENT,
	RECRUITER_JOB_FRAGMENT,
	SALESFORCE_INTEGRATION_FRAGMENT,
	WORK_EXPERIENCE_FRAGMENT,
	WORK_EXPERIENCE_OVERVIEW_FRAMENT,
	WORKED_TIMESHEET_FRAGMENT,
	WORKER_ADDRESS_FRAGMENT,
	WORKER_ASSIGNMENT_FRAGMENT,
	WORKER_CERTIFICATION_FRAGMENT,
	WORKER_CREDENTIAL_FRAGMENT,
	WORKER_FRAGMENT,
	WORKER_REFERENCE_FRAGMENT,
	WORKING_AUTHORIZATION_FRAGMENT,
} from '../apollo.fragment'
import { COMPANY } from '~/common/constants/company.constant'

export const VERIFY_WORKER_QUERY = gql`
	query verifyWorker($token: String!) {
		workerToken(token: $token) {
			token
			worker {
				...workerFragment
				recruiter {
					id
					name
					phone
					email
					title
				}
				company {
					salesforceIntegration {
						...salesforceIntegrationFragment
					}
				}
			}
		}
	}

	${WORKER_FRAGMENT}
	${SALESFORCE_INTEGRATION_FRAGMENT}
`

export const GET_RECRUITER_WORKER_DETAIL_QUERY = gql`
	query recruiterWorkerDetail($id: String!) {
		recruiterWorker(workerId: $id) {
			...workerFragment
			workExperienceOverview {
				...workExperienceOverview
			}
			workingAuthorization {
				...workingAuthorizationFragment
			}
			workExperiences {
				...workExperienceFragment
			}
			workerCertifications {
				...workerCertificationFragment
			}
			emergencyContact {
				...emergencyContactFragment
			}
			workerAddress {
				...workerAddressFragment
			}
			supportDocuments {
				id
				documentType
				document {
					id
					contentType
					fileUrl
					filename
					thumbnails
					blobId
					imageUrls(size: ["100x100", "200x200", "500x500"])
				}
			}
			workerReferences {
				...workerReferenceFragment
			}
			workingPreferredLocations {
				id
				preferredWorkingCity
				preferredWorkingState
			}
		}
	}

	${WORKER_FRAGMENT}
	${WORKING_AUTHORIZATION_FRAGMENT}
	${WORK_EXPERIENCE_FRAGMENT}
	${WORKER_CERTIFICATION_FRAGMENT}
	${EMERGENCY_CONTACT_FRAGMENT}
	${WORK_EXPERIENCE_OVERVIEW_FRAMENT}
	${WORKER_ADDRESS_FRAGMENT}
	${WORKER_REFERENCE_FRAGMENT}
`

export const GET_WORKER_DETAIL_FOR_TIMECARDS_QUERY = gql`
	query getWorkerDetailTimeQuery($id: String!) {
		worker(id: $id) {
			...workerFragment
			company {
				salesforceIntegration {
					...salesforceIntegrationFragment
				}
			}
			workedTimesheets {
				...workedTimesheetFragment
				timesheetImages {
					imageUrls(size: ["100x100", "200x200"])
					...imageFileFragment
				}
			}
		}
	}

	${WORKER_FRAGMENT}
	${SALESFORCE_INTEGRATION_FRAGMENT}
	${WORKED_TIMESHEET_FRAGMENT}
	${IMAGE_FILE_FRAGMENT}
`

export const GET_CREDENTIALING_URL_QUERY = gql`
	query getWorkerDetailCredentialingQuery($id: String!) {
		worker(id: $id) {
			id
			credentialingUrl
		}
	}
`

export const GET_WORKER_CERTIFICATIONS_QUERY = gql`
	query getWorkerCertificationsQuery($id: String!) {
		workerCertifications(workerId: $id) {
			...workerCertificationFragment
			licenseImages {
				...imageFileFragment
			}
		}
	}

	${WORKER_CERTIFICATION_FRAGMENT}
	${IMAGE_FILE_FRAGMENT}
`

export const GET_WORKER_CREDENTIALS_QUERY = gql`
	query getWorkerCredentialsQuery($filter: WorkerCredentialStatusEnum, $after: String, $before: String, $first: Int, $last: Int) {
		workerCredentials(filter: $filter, after: $after, before: $before, first: $first, last: $last) {
			totalCount
			pageInfo {
				hasNextPage
				hasPreviousPage
				endCursor
			}
			nodes {
				...workerCredentialFragment
			}
		}
	}

	${WORKER_CREDENTIAL_FRAGMENT}
`

export const GET_WORKER_SOCIAL_QUERY = gql`
	query getWorkerSocialQuery {
		workerSocialAuthentications {
			providerName
			id
			providerUuid
			email
		}
	}
`

export const RECRUITER_SEARCH_WORKERS_QUERY = gql`
	query recruiterSearchWorkersQuery($keyword: String, $filter: WorkerFilter, $sort: SortArgument, $after: String, $before: String, $first: Int, $last: Int) {
		recruiterSearchWorkers(keyword: $keyword, filter: $filter, sort: $sort, after: $after, before: $before, first: $first, last: $last) {
			totalCount
			pageInfo {
				hasNextPage
				hasPreviousPage
				endCursor
			}
			nodes {
				...workerFragment
			}
		}
	}

	${WORKER_FRAGMENT}
`

export const RECRUITER_WORKER_ON_ASSIGNMENTS_QUERY = gql`
	query recruiterWorkerOnAssignmentsQuery($sort: SortArgument, $after: String, $before: String, $first: Int, $last: Int) {
		recruiterWorkerOnAssignments(sort: $sort, after: $after, before: $before, first: $first, last: $last) {
			totalCount
			pageInfo {
				hasNextPage
				hasPreviousPage
				endCursor
			}
			nodes {
				...workerAssignmentFragment
			}
		}
	}

	${WORKER_ASSIGNMENT_FRAGMENT}
`

export const RECRUITER_SEARCH_JOBS_QUERY = gql`
	query recruiterSearchJobsQuery($filter: RecruiterJobFilter, $keyword: String, $sort: SortArgument, $after: String, $before: String, $first: Int, $last: Int) {
		recruiterSearchJobs(keyword: $keyword, filter: $filter, sort: $sort, after: $after, before: $before, first: $first, last: $last) {
			totalCount
			pageInfo {
				hasNextPage
				hasPreviousPage
				endCursor
			}
			nodes {
				id
				skillLongName
				title
				startDate
				duration
				exclusiveJob
				discipline
				specialty
				remainingSlotNumbers
				slotsNumber
				facilityType
				jobApplicantCount
				noQualityMatches
				noTalentMatches
				exclusiveJob
				internalSub
				externalSub
				marginPercentage
				externalJobId
				skillLongName
				${process.env.REACT_APP_COMPANY_HOST === COMPANY.OPUS ? 'client {name}' : ''}
			}
		}
	}
`

export const GET_RECRUITER_JOB_DETAIL_QUERY = gql`
	query getRecruiterJob($jobId: String!) {
		recruiterJob(id: $jobId) {
			...recruiterJobFragment
		}
	}

	${RECRUITER_JOB_FRAGMENT}
`

export const GET_RECRUITER_JOB_APPLICANT_ACTIVITIES_QUERY = gql`
	query getRecruiterJobApplicantActivities($trackableType: String!, $workerId: String!, $trackableId: String!) {
		activities(trackableType: $trackableType, workerId: $workerId, trackableId: $trackableId) {
			id
			key
			message
			owner {
				id
				firstName
				lastName
				fullName
			}
			trackable {
				id
				job {
					id
					title
				}
			}
			createdAt
		}
	}
`

export const GET_RECRUITER_JOB_APPLICANT_QUERY = gql`
	query getRecruiterJobApplicant($id: String!) {
		recruiterJobApplicant(id: $id) {
			...jobApplicantFragment
		}
	}

	${JOB_APPLICANT_FRAGMENT}
`

export const GET_RECRUITER_JOB_DETAIL_TITLE_QUERY = gql`
	query getRecruiterJob($jobId: String!) {
		recruiterJob(id: $jobId) {
			title
		}
	}
`

export const GET_RECRUITER_JOB_APPLICANTS_QUERY = gql`
	query recruiterJobApplicantsQuery(
		$workerId: String
		$jobId: String
		$jobApplicantScope: String
		$sort: SortArgument
		$after: String
		$before: String
		$first: Int
		$last: Int
	) {
		recruiterJobApplicants(
			workerId: $workerId
			jobId: $jobId
			jobApplicantScope: $jobApplicantScope
			sort: $sort
			after: $after
			before: $before
			first: $first
			last: $last
		) {
			totalCount
			pageInfo {
				hasNextPage
				hasPreviousPage
				endCursor
			}
			nodes {
				...jobApplicantFragment
			}
		}
	}

	${JOB_APPLICANT_FRAGMENT}
`

export const GET_RECRUITER_JOB_APPLICANT_WORKERS_QUERY = gql`
	query recruiterJobApplicantWorkersQuery(
		$keyword: String
		$filterScope: String
		$sort: SortArgument
		$after: String
		$before: String
		$first: Int
		$last: Int
	) {
		recruiterJobApplicantWorkers(keyword: $keyword, filterScope: $filterScope, sort: $sort, after: $after, before: $before, first: $first, last: $last) {
			totalCount
			pageInfo {
				hasNextPage
				hasPreviousPage
				endCursor
			}
			nodes {
				...workerFragment
			}
		}
	}

	${WORKER_FRAGMENT}
`

export const GET_RECRUITER_WORKER_JOB_APPLICANTS_QUERY = gql`
	query recruiterWorkerJobApplicantsQuery(
		$workerId: String
		$jobApplicantScope: JobApplicantScopeEnum
		$filterScope: JobApplicantFilterScopeEnum
		$sort: SortArgument
		$first: Int
	) {
		recruiterWorkerJobApplicants(
			filter: { workerId: $workerId, jobApplicantScope: $jobApplicantScope, filterScope: $filterScope }
			sort: $sort
			first: $first
		) {
			totalCount
			pageInfo {
				hasNextPage
				hasPreviousPage
				endCursor
			}
			nodes {
				id
				stage
				stageName
				applyDate
				submittal {
					id
					status
					submittalDate
				}
				job {
					id
					title
					slotsNumber
					matchingPercentage
					remainingSlotNumbers
				}
			}
		}
	}
`

export const GET_RECRUITER_WORKER_JOB_MATCHES_FULL_QUERY = gql`
	query recruiterWorkerJobMatchesFullQuery($workerId: String!, $sort: SortArgument, $after: String, $before: String, $first: Int, $last: Int) {
		recruiterWorkerJobMatches(workerId: $workerId, sort: $sort, after: $after, before: $before, first: $first, last: $last) {
			totalCount
			pageInfo {
				hasNextPage
				hasPreviousPage
				endCursor
			}
			nodes {
				...jobFragment
			}
		}
	}

	${JOB_FRAGMENT}
`

export const GET_RECRUITER_WORKER_JOB_APPLICANTS_FULL_QUERY = gql`
	query recruiterWorkerJobApplicantsQuery(
		$workerId: String
		$jobApplicantScope: JobApplicantScopeEnum
		$filterScope: JobApplicantFilterScopeEnum
		$sort: SortArgument
		$after: String
		$before: String
		$first: Int
		$last: Int
	) {
		recruiterWorkerJobApplicants(
			filter: { workerId: $workerId, jobApplicantScope: $jobApplicantScope, filterScope: $filterScope }
			sort: $sort
			after: $after
			before: $before
			first: $first
			last: $last
		) {
			totalCount
			pageInfo {
				hasNextPage
				hasPreviousPage
				endCursor
			}
			nodes {
				id
				submittal {
					id
					status
					submittalDate
				}
				applyDate
				stage
				workerAssignments {
					id
					endDate
					status
				}
				job {
					...jobFragment
					startDate
				}
			}
		}
	}

	${JOB_FRAGMENT}
`

export const GET_RECRUITER_WORKER_BOOKMARKED_JOBS_QUERY = gql`
	query recruiterWorkerBookmarkedJobsQuery($workerId: String!, $after: String, $before: String, $first: Int, $last: Int) {
		recruiterWorkerBookmarkedJobs(workerId: $workerId, after: $after, before: $before, first: $first, last: $last) {
			totalCount
			pageInfo {
				hasNextPage
				hasPreviousPage
				endCursor
			}
			nodes {
				id
				...jobFragment
			}
		}
	}

	${JOB_FRAGMENT}
`

export const GET_RECRUITER_WORKER_LAST_VIEWED_JOBS_QUERY = gql`
	query getRecruiterWorkerLastViewedJobs($activityType: JobActivityTypeEnum, $workerId: String!, $after: String, $before: String, $first: Int, $last: Int) {
		recruiterWorkerLastViewedJobs(workerId: $workerId, activityType: $activityType, after: $after, before: $before, first: $first, last: $last) {
			totalCount
			pageInfo {
				hasNextPage
				hasPreviousPage
				endCursor
			}
			nodes {
				id
				location {
					region
					city
					zipcode
					addressState
				}
				customStartDate
				createdAt
				discipline
				duration
				facilityType
				hospital
				id
				jobType
				externalCreatedAt
				matchingPercentage
				minWeeklyPayAmount
				shifts
				skillLongName
				specialty
				startDate
				status
				title
				updatedAt
				isAsap
				viewCount
				weeklyPayAmount
				slotsNumber
			}
		}
	}
`

export const GET_RECRUITER_WORKER_RECOMMENDED_JOBS_QUERY = gql`
	query recruiterWorkerSuggestionJobs($workerId: String!, $after: String, $before: String, $first: Int, $last: Int) {
		recruiterWorkerSuggestionJobs(workerId: $workerId, after: $after, before: $before, first: $first, last: $last) {
			totalCount
			pageInfo {
				hasNextPage
				hasPreviousPage
				endCursor
			}
			nodes {
				id
				...jobFragment
			}
		}
	}

	${JOB_FRAGMENT}
`

export const GET_RECRUITER_WORKER_ACTIVITIES_QUERY = gql`
	query recruiterWorkerActivities($workerId: String!, $activityTypes: [WorkerActivityTypeEnum!], $after: String, $before: String, $first: Int, $last: Int) {
		recruiterWorkerActivities(workerId: $workerId, activityTypes: $activityTypes, after: $after, before: $before, first: $first, last: $last) {
			totalCount
			pageInfo {
				hasNextPage
				hasPreviousPage
				endCursor
			}
			nodes {
				id
				key
				createdAt
				owner {
					id
					fullName
					firstName
					lastName
				}
				trackable {
					__typename
					... on Job {
						id
						title
					}
					... on JobApplicant {
						id
						job {
							id
							title
						}
					}
					... on FilterCondition {
						id
					}
				}
			}
		}
	}
`

export const GET_WORKER_JOB_MATCHES_QUERY = gql`
	query recruiterWorkerJobMatchesQuery($workerId: String!, $sort: SortArgument, $first: Int) {
		recruiterWorkerJobMatches(workerId: $workerId, sort: $sort, first: $first) {
			totalCount
			nodes {
				id
				title
				startDate
				customStartDate
				exclusiveJob
				slotsNumber
				remainingSlotNumbers
				matchingPercentage
				isAsap
			}
		}
	}
`

export const GET_RECRUITER_STATISTICS_QUERY = gql`
	query recruiterStatisticsQuery {
		recruiterStatistics {
			activeAssignmentsTotal
			assignmentTotal
			activeJobTotal
			noJobHasMatchesTotal
			noJobHasSubmissionTotal
			matchesTotal
			preSubmissionTotal
			submissionTotal
			dashboardSubmissionTotal
			workerTotal

			noToasTrendingPercentage
			availableTravelerTrendingPercentage
			noQualityMatchesTrendingPercentage

			noToasCurrentPeriodTotal
			availableTravelerCurrentPeriodTotal
			noQualityMatchesCurrentPeriodTotal
			activeAssignmentsTotal
		}
	}
`

export const GET_RECRUITER_WORKER_STATISTICS_QUERY = gql`
	query recruiterWorkerStatisticsQuery($workerId: String!) {
		recruiterWorkerStatistics(workerId: $workerId) {
			assignmentTotal
			lastEngagementScore
			lastJobSearchTotal
		}
	}
`

export const GET_MASTER_DATA = gql`
	query getMasterData {
		appInit {
			masterData
		}
	}
`

export const RECRUITER_WORKER_ASSIGNMENTS_QUERY = gql`
	query recruiterWorkerAssignmentsQuery($filter: WorkerAssignmentFilter, $sort: SortArgument, $limit: Int, $offset: Int) {
		recruiterWorkerAssignments(sort: $sort, filter: $filter, limit: $limit, offset: $offset) {
			totalCount
			workerAssignments {
				id
				worker {
					id
					avatar {
						fileUrl
					}
					fullName
					lastName
					firstName
					statistic {
						engagementScore
					}
					profilePercentageCompleted
				}
				retentionStatus
				job {
					id
					title
					facilityType
					marginPercentage
				}
				endDate
				client {
					name
				}
			}
		}
	}
`

export const RECRUITER_SEARCH_CANDIDATES_QUERY = gql`
	query recruiterSearchCandidatesQuery($fullName: String, $keyword: String, $first: Int, $after: String) {
		recruiterSearchWorkers(
			keyword: $keyword
			filter: { workerScope: active, fullName: $fullName }
			sort: { field: "fullName", order: desc }
			first: $first
			after: $after
		) {
			totalCount
			pageInfo {
				hasNextPage
				hasPreviousPage
				endCursor
			}
			nodes {
				...workerFragment
			}
			pageInfo {
				hasNextPage
				hasPreviousPage
				endCursor
			}
		}
	}
	${WORKER_FRAGMENT}
`

export const RECRUITER_WORKER_ASSIGNMENTS__JOBS_INFO_QUERY = gql`
	query($jobId: String!, $workerAssignmentScope: FilterWorkerAssignmentScopeEnum, $sort: SortArgument) {
		recruiterWorkerAssignments(filter: { workerAssignmentScope: $workerAssignmentScope, jobId: $jobId }, sort: $sort) {
			totalCount
			workerAssignments {
				id
				worker {
					id
					fullName
					firstName
					lastName
					phoneVerifiedAt
					avatarUrl
					statistic {
						engagementScore
					}
					avatar {
						fileUrl
					}
				}
				job {
					title
					facilityType
				}
				client {
					name
				}
				stage
				stageName
				status
				endDate
				startDate
			}
		}
	}
`

export const RECRUITER_JOB_WORKER_MATCHES_QUERY = gql`
	query recruiterJobWorkerMatchesQuery($jobId: String!, $sort: SortArgument, $after: String, $first: Int, $filter: RecruiterJobFilter) {
		recruiterJobWorkerMatches(jobId: $jobId, sort: $sort, after: $after, first: $first, filter: $filter) {
			totalCount
			pageInfo {
				hasNextPage
				hasPreviousPage
				endCursor
			}
			nodes {
				id
				fullName
				firstName
				lastName
				primaryWorkerSpecialty {
					id
					specialty
					category
					specialtyLongName
				}
				matchingPercentage
			}
		}
	}
`

export const GET_RECRUITER_WORKER_JOB_APPLICANTS_JOB_INFO_QUERY = gql`
	query recruiterWorkerJobApplicantsQuery($jobId: String, $jobApplicantScope: JobApplicantScopeEnum, $sort: SortArgument, $after: String, $first: Int) {
		recruiterWorkerJobApplicants(filter: { jobId: $jobId, jobApplicantScope: $jobApplicantScope }, after: $after, sort: $sort, first: $first) {
			totalCount
			pageInfo {
				hasNextPage
				hasPreviousPage
				endCursor
			}
			nodes {
				id
				applyDate
				stage
				stageName
				submittal {
					id
					status
					submittalDate
				}
				job {
					id
					title
					slotsNumber
					matchingPercentage
					remainingSlotNumbers
				}
				worker {
					...workerFragment
				}
			}
		}
	}
	${WORKER_FRAGMENT}
`

export const GET_JOB_DETAIL_STATISTICS = gql`
	query($jobId: String!) {
		recruiterJobStatistics(jobId: $jobId) {
			activeAssignmentsTotal
			assignmentsTotal
			submissionsTotal
			matchesTotal
		}
	}
`
export const RECRUITER_JOB_WORKER_ASSIGNMENTS_QUERY = gql`
	query recruiterWorkerAssignmentsQuery($sort: SortArgument, $filter: WorkerAssignmentFilter, $limit: Int, $offset: Int) {
		recruiterWorkerAssignments(sort: $sort, filter: $filter, limit: $limit, offset: $offset) {
			totalCount
			workerAssignments {
				id
				endDate
				job {
					id
					title
					exclusiveJob
					facilityType
					marginPercentage
				}
				client {
					id
					name
				}
				worker {
					id
					fullName
					firstName
					lastName
					email
					avatar {
						fileUrl
					}
				}
			}
		}
	}
`
