import { gql } from '@apollo/client'

export const GET_SUPERSET_CONFIGURATION_QUERY = gql`
	query getSupersetConfigurationQuery {
		recruiterSupersetConfiguration {
			guestToken
			embedId
		}
	}
`
