import { gql } from '@apollo/client'
import { JOB_FRAGMENT, WORKER_FRAGMENT } from '../apollo.fragment'

export const GET_DISCOVER_VIEW_AS_CANDIDATE_QUERY = gql`
	query recruiterWorkerJobDiscovers($workerId: String!) {
		recruiterWorkerJobDiscovers(workerId: $workerId) {
			available
			trending
			bookmarkedJobs
			lastViewedJobs
			lastSearch {
				type
				filterConditions
				count
			}
		}
	}
`

export const GET_GUIDANCE_VIEW_AS_CANDIDATE_QUERY = gql`
	query recruiterWorkerBulletins($workerId: String!) {
		recruiterWorkerBulletins(workerId: $workerId) {
			id
			name
			title
			content
			kind
			allowDismiss
			data
		}
	}
`

export const GET_RECRUITER_POPULAR_CITIES_QUERY = gql`
	query getGeneralRankedJobsQuery($workerId: String!) {
		recruiterPopularCities(workerId: $workerId) {
			city
			cityImage
			state
			avgWeeklyRate
			totalJobs
		}
	}
`

export const GET_DETAIL_RANKED_JOBS_QUERY = gql`
	query getRankedJobsQuery($state: String!, $city: String!, $after: String, $before: String, $first: Int, $last: Int) {
		detailPopularCity(state: $state, city: $city, after: $after, before: $before, first: $first, last: $last) {
			totalCount
			pageInfo {
				hasNextPage
				hasPreviousPage
				endCursor
			}
			nodes {
				city
				state
				avgWeeklyRate
				totalJobs
				rankedJobs {
					matchingPercentage
					job {
						...jobFragment
					}
				}
			}
		}
	}

	${JOB_FRAGMENT}
`

export const GET_SUGGESTION_JOBS_QUERY = gql`
	query getSuggestionJobsQuery($after: String, $before: String, $first: Int, $last: Int) {
		suggestionJobs(after: $after, before: $before, first: $first, last: $last) {
			totalCount
			pageInfo {
				hasNextPage
				hasPreviousPage
				endCursor
			}
			nodes {
				...jobFragment
			}
		}
	}

	${JOB_FRAGMENT}
`

export const GET_ACTIVE_JOBS_QUERY = gql`
	query getActiveJobsQuery {
		activeJobs {
			completedJobs {
				...jobFragment
			}
			currentJobs {
				...jobFragment
			}
			nextJobs {
				...jobFragment
			}
		}
	}

	${JOB_FRAGMENT}
`

export const GET_APPLIED_JOBS_QUERY = gql`
	query getAppliedJobsQuery($filter: JobApplicantStatusEnum, $offset: Int, $limit: Int) {
		appliedJobs(filter: $filter, offset: $offset, limit: $limit) {
			id
			status
			createdAt
			updatedAt
			job {
				...jobFragment
				jobApplicant {
					status
				}
			}
			worker {
				...workerFragment
			}
		}
	}

	${JOB_FRAGMENT}
	${WORKER_FRAGMENT}
`

export const GET_FAVORITED_JOBS_QUERY = gql`
	query getfavoritedJobsQuery($after: String, $before: String, $first: Int, $last: Int) {
		favoritedJobs(after: $after, before: $before, first: $first, last: $last) {
			totalCount
			pageInfo {
				hasNextPage
				hasPreviousPage
				endCursor
			}
			nodes {
				...jobFragment
			}
		}
	}

	${JOB_FRAGMENT}
`
