import axios from 'axios'
import { isEmpty } from 'lodash'
import { MASTER_DATA_FIELDS } from '~/common/constants'
import { action, observable, persist, store } from '~/common/mobx.decorator'
import { apolloClient, LIKE_DISLIKE_JOB_MUTATION } from '~/common/apollo'
import { notifyStore } from '~/stores'

@store()
class MasterStore {
	@observable disciplines
	@observable locations
	@observable referrals
	@observable agencies
	@observable chartings
	@observable primarySpecialities
	@observable secondarySpecialities
	@persist @observable licenseStates
	@observable referralRelationships
	@observable jobDisciplines
	@observable jobShifts
	@observable profession
	@observable originalSpecialties = {}
	@observable specialties = []
	@observable reasons = []
	@observable locations = []
	@observable salutations = []
	@observable relationships = []
	@observable documentTypes = []
	@observable facilityTypes = []
	@observable referredRelationships = []
	@observable workLocations = []
	@observable originalLocations = []
	@observable assignmentTypes = []
	@observable cityStates = []
	@persist @observable specialtyOptions = []
	@persist @observable listSpecialtyName = []
	@persist @observable jobTypes
	@persist @observable masterData = {}
	@persist @observable frequencyMasterData = {}
	@persist @observable alertChannelMasterData = {}
	@persist @observable pauseAlertPeriodMasterData = {}

	@action
	fetchDisciplines = async (force = false) => {
		if (!force && !isEmpty(this.disciplines)) {
			return
		}

		const response = await axios.get(`/company_configs/picklists`, {
			params: {
				picklist_type: MASTER_DATA_FIELDS.specialty,
			},
		})
		this.disciplines = response.data
	}

	@action
	fetchTypeJobs = async (force = false) => {
		if (!force && !isEmpty(this.disciplines)) {
			return
		}

		const response = await axios.get(`/company_configs/picklists`, {
			params: {
				picklist_type: MASTER_DATA_FIELDS.jobType,
			},
		})
		this.jobTypes = Object.keys(response.data).map((key) => ({
			label: key,
			value: key,
		}))
	}

	@action
	fetchJobDisciplines = async (force = false) => {
		if (!force && !isEmpty(this.jobDisciplines)) {
			return
		}

		const response = await axios.get(`/company_configs/picklists`, {
			params: {
				picklist_type: 'specialty',
			},
		})

		this.profession = Object.keys(response.data).map((key) => ({
			label: key,
			value: key,
		}))

		this.specialtyOptions = Object.entries(response.data).map(([key, value]) => ({
			label: key,
			value: key,
			child: Object.entries(value).map(([key, value]) => {
				return {
					label: value.long_name,
					value: value.skill_name,
				}
			}),
		}))

		this.listSpecialtyName = Object.entries(response.data)
			.map(([key, value]) => ({
				label: key,
				value: key,
				child: Object.entries(value).map(([key, value]) => {
					return {
						label: value.long_name,
						value: value.skill_name,
					}
				}),
			}))
			.map((item) => item.child)
			.flat(1)

		this.jobDisciplines = response.data
	}

	@action
	fetchJobShifts = async (force = false) => {
		if (!force && !isEmpty(this.jobShifts)) {
			return
		}

		const response = await axios.get(`/company_configs/picklists`, {
			params: {
				picklist_type: 'shift',
			},
		})
		this.jobShifts = Object.entries(response.data).map(([key, value]) => ({ label: value, value: key }))
	}

	@action
	fetchLocations = async (force = false) => {
		if (!force && !isEmpty(this.locations)) {
			return
		}

		const response = await axios.get(`/company_configs/picklists`, {
			params: {
				picklist_type: MASTER_DATA_FIELDS.preferredLocations,
			},
		})

		this.locations = response.data
	}

	@action
	fetchReferralRelationships = async (force = false) => {
		if (!force && !isEmpty(this.referralRelationships)) {
			return
		}

		const response = await axios.get(`/company_configs/picklists`, {
			params: {
				picklist_type: MASTER_DATA_FIELDS.referralRelationship,
			},
		})

		this.referralRelationships = response.data
	}

	@action
	fetchTravelerRelationship = async (force = false) => {
		if (!force && !isEmpty(this.referredRelationships)) {
			return
		}
		const response = await axios.get(`/company_configs/master_data`)

		const relationships = response?.data?.referred_traveler?.relationship
		let data = Object.keys(relationships).map((key) => ({
			label: relationships[key],
			value: key,
		}))

		this.referredRelationships = data
	}

	@action
	fetchReferrals = async (force = false) => {
		if (!force && !isEmpty(this.referrals)) {
			return
		}

		const response = await axios.get(`/company_configs/picklists`, {
			params: {
				picklist_type: MASTER_DATA_FIELDS.referral,
			},
		})

		this.referrals = response.data
	}

	@action
	fetchAgencies = async (force = false) => {
		if (!force && !isEmpty(this.agencies)) {
			return
		}

		const response = await axios.get(`/company_configs/picklists`, {
			params: {
				picklist_type: MASTER_DATA_FIELDS.agency,
			},
		})

		this.agencies = response.data
	}

	@action
	fetchChartings = async (force = false) => {
		if (!force && !isEmpty(this.chartings)) {
			return
		}

		const response = await axios.get(`/company_configs/picklists`, {
			params: {
				picklist_type: MASTER_DATA_FIELDS.charting,
			},
		})

		this.chartings = response.data
	}

	@action
	fetchSecondarySpecialities = async (force = false) => {
		if (!force && !isEmpty(this.secondarySpecialities)) {
			return
		}

		const response = await axios.get(`/company_configs/picklists`, {
			params: {
				picklist_type: MASTER_DATA_FIELDS.secondarySpecialty,
			},
		})

		this.secondarySpecialities = response.data
	}

	@action
	fetchLicenseStates = async (force = false) => {
		if (!force && !isEmpty(this.licenseStates)) {
			return
		}

		const response = await axios.get(`/company_configs/picklists`, {
			params: {
				picklist_type: MASTER_DATA_FIELDS.licenseState,
			},
		})
		this.licenseStates = Object.entries(response.data).map(([key, value]) => ({ label: value, value: key }))
	}

	@action
	fetchPrimarySpecialities = async (force = false) => {
		if (!force && !isEmpty(this.primarySpecialities)) {
			return
		}

		const response = await axios.get(`/company_configs/picklists`, {
			params: {
				picklist_type: MASTER_DATA_FIELDS.specialty,
			},
		})

		this.primarySpecialities = response.data
	}

	@action
	onFavoriteJob = async (values) => {
		const { jobId, action } = values?.variables
		try {
			const response = await apolloClient.mutate({
				mutation: LIKE_DISLIKE_JOB_MUTATION,
				variables: {
					jobId: jobId,
					action: action,
				},
			})
			notifyStore.success('$MESSAGES.SUCCESSFUL')
			return response?.data?.likeOrDislikeAJob
		} catch (error) {
			notifyStore.error(error.message)
		}
	}

	@action
	fetchRelationships = async (force = false) => {
		if (!force && !isEmpty(this.relationships)) {
			return
		}

		const response = await axios.get(`/company_configs/master_data`)
		this.relationships = response?.data?.worker_reference?.relationship
	}

	@action
	fetchSalutations = async (force = false) => {
		if (!force && !isEmpty(this.salutations)) {
			return
		}

		const response = await axios.get(`/company_configs/master_data`)
		this.salutations = response?.data?.worker_reference?.salutation
	}

	@action
	fetchReasonLeavings = async (force = false) => {
		if (!force && !isEmpty(this.reasons)) {
			return
		}
		const response = await axios.get(`/company_configs/master_data`)
		this.reasons = response?.data?.work_experience?.reason_for_leaving
	}

	@action
	fetchAssignmentTypes = async (force = false) => {
		if (!force && !isEmpty(this.assignmentTypes)) {
			return
		}
		const response = await axios.get(`/company_configs/master_data`)
		this.assignmentTypes = response?.data?.work_experience?.assignment_type
	}

	@action
	fetchSpecialties = async (force = false) => {
		if (!force && !isEmpty(this.specialties)) {
			return
		}

		const response = await axios.get(`/company_configs/picklists`, {
			params: {
				picklist_type: MASTER_DATA_FIELDS.specialty,
			},
		})

		this.originalSpecialties = response.data
		let data = []
		Object.values(response.data).map((item, index) => {
			let dataArray = Object.values(item)
			const category = Object.keys(response.data)[index]
			dataArray = dataArray.map((item) => ({ ...item, category: category }))
			data = [...data, ...dataArray]
			return data
		})

		this.specialties = data
	}

	@action
	fetchSupportDocumentTypes = async (force = false) => {
		if (!force && !isEmpty(this.documentTypes)) {
			return
		}

		const response = await axios.get(`/company_configs/picklists`, {
			params: {
				picklist_type: MASTER_DATA_FIELDS.documentType,
			},
		})

		this.documentTypes = response.data
	}

	@action
	fetchFacilityTypes = async (force = false) => {
		if (!force && !isEmpty(this.facilityTypes)) {
			return
		}
		const response = await axios.get(`/company_configs/master_data`)
		this.facilityTypes = response?.data?.work_experience?.facility_type
	}

	@action
	fetchWorkLocations = async (force = false) => {
		if (!force && !isEmpty(this.workLocations)) {
			return
		}

		const response = await axios.get(`/company_configs/picklists`, {
			params: {
				picklist_type: MASTER_DATA_FIELDS.state,
			},
		})

		const locations = response?.data

		this.originalLocations = response?.data
		let data = Object.keys(locations).map((key) => ({
			label: locations[key],
			value: key,
		}))

		this.workLocations = data
	}

	@action
	fetchCityState = async (force = false, input) => {
		if (!force && !isEmpty(this.cityStates)) {
			return
		}

		const response = await axios.get(`/cities/search?terms=${input}`)

		const locations = response?.data?.data

		this.originalLocations = response?.data
		let data = Object.keys(locations).map((key) => ({
			label: locations[key],
			value: key,
		}))

		this.cityStates = data
	}
}

export const masterStore = new MasterStore()
