import { css, createGlobalStyle } from 'styled-components'

export const GlobalStyle = createGlobalStyle`
	body {
		color: ${({ theme }) => theme.palette.text.primary};
		background-color: ${({ theme }) => theme.palette.background.default};
	}
	
	input:-webkit-autofill,
	input:-webkit-autofill:hover, 
	input:-webkit-autofill:focus, 
	input:-webkit-autofill:active  {
		box-shadow: 0 0 0 30px white inset !important;
	}
	.grecaptcha-badge {
		display: none;
	}

	.pac-container {
		z-index: 3000;
	}
    form label.MuiFormLabel-root.Mui-error {
      color: ${({ theme }) => theme.palette.text.tertiary};
    }
`

export const linearProgressStyle = css`
	position: fixed;
	top: 0;
	width: 100vw;
	height: 3px;
	z-index: ${({ theme }) => theme.zIndex.drawer + 1};
`
