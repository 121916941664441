import { gql } from '@apollo/client'

export const EMERGENCY_CONTACT_FRAGMENT = gql`
	fragment emergencyContactFragment on EmergencyContact {
		id
		contactName
		contactPhone
		contactRelationship
	}
`

export const WORKER_ADDRESS_FRAGMENT = gql`
	fragment workerAddressFragment on WorkerAddress {
		id
		zipcode
		street
		city
		state
		country
		aptNumber
	}
`

export const WORKER_FRAGMENT = gql`
	fragment workerFragment on Worker {
		id
		email
		firstName
		lastName
		fullName
		currentSignInAt
		lastToken {
			platform
			userAgent
		}
		noTalentMatches
		externalId
		resumes {
			id
			filename
			fileUrl
			thumbnails
			contentType
			blobId
			imageUrls(size: ["100x100", "200x200", "500x500"])
		}
		avatarUrl
		phone
		phoneVerifiedAt
		workAuthorized
		maidenName
		jobApplicantCount
		submittalCount
		appliedSubmittalCount
		activeSubmissionCount
		statistic {
			engagementScore
		}
		availableStartDate
		timeOffRequested
		shiftRequested
		primaryWorkerSpecialty {
			id
			category
			isPrimary
			specialtyLongName
		}
		avatar {
			fileUrl
		}
		profilePercentageCompleted
		submittalCount
	}
`

export const LOCATION_FRAGMENT = gql`
	fragment locationFragment on Location {
		id
		address
		addressState
		city
		country
		zipcode
	}
`

export const ACTIVE_JOB_FRAGMENT = gql`
	fragment jobFragment on Job {
		id
		title
		externalJobId
		startDate
		duration
		matchingPercentage
		weeklyPayAmount
		minWeeklyPayAmount
		hourlyPayHigh
		hourlyPayLow
		stipends
		taxableHigh
		taxableLow
		specialty
		hospital
		discipline
		isFavorited
		status
		expiredDate
		locationImage {
			thumbnailImage {
				imageUrls(size: ["100x100"])
			}
			webImage {
				fileUrl
			}
		}
		location {
			...locationFragment
		}
		jobApplicant {
			id
			status
			workerAssignment {
				endDate
			}
		}
		shifts
		hoursPerWeek
		shiftsPerWeek
		externalJobId
		licensesRequired
		licenseStateRequired
		certificationsRequired
	}
	${LOCATION_FRAGMENT}
`

export const JOB_FRAGMENT = gql`
	fragment jobFragment on Job {
		location {
			region
			city
			zipcode
			addressState
		}
		locationImage {
			thumbnailImage {
				imageUrls(size: ["100x100"])
			}
			webImage {
				fileUrl
			}
		}
		customStartDate
		applicantCount
		createdAt
		discipline
		duration
		facilityType
		hospital
		frequency
		id
		jobType
		externalCreatedAt
		matchingPercentage
		minWeeklyPayAmount
		shifts
		skillLongName
		specialty
		startDate
		status
		title
		updatedAt
		isAsap
		viewCount
		weeklyPayAmount
		slotsNumber
	}
`

export const RECRUITER_JOB_FRAGMENT = gql`
	fragment recruiterJobFragment on RecruiterJob {
		id
		title
		externalJobId
		isAsap
		startDate
		customStartDate
		duration
		weeklyPayAmount
		minWeeklyPayAmount
		specialty
		hospital
		discipline
		status
		jobType
		locationImage {
			id
			thumbnailImage {
				imageUrls(size: ["100x100"])
			}
			webImage {
				fileUrl
			}
		}
		location {
			...locationFragment
		}
		facilityType
		shifts
		hoursPerWeek
		externalJobId
		licensesRequired
		licenseStateRequired
		certificationsRequired
		description
		jobApplicantCount
		licenseStateCompact
		noQualityMatches
		slotsNumber
		licenseStateRequired
		certificationsRequired
		matchingPercentage
		skillLongName
	}
	${LOCATION_FRAGMENT}
`

export const COMPANY_FRAGMENT = gql`
	fragment companyFragment on Company {
		address
		addressState
		city
		country
		description
		id
		mainContactEmail
		mainContactName
		companyConfig {
			enableHourlySearch
		}
		name
		phone
		zip
	}
`

export const WORKER_CREDENTIAL_FRAGMENT = gql`
	fragment workerCredentialFragment on WorkerCredential {
		company {
			...companyFragment
		}
		createdAt
		expirationDate
		id
		name
		status
		worker {
			...workerFragment
		}
	}
	${WORKER_FRAGMENT}
	${COMPANY_FRAGMENT}
`

export const WORKING_AUTHORIZATION_FRAGMENT = gql`
	fragment workingAuthorizationFragment on WorkingAuthorization {
		id
		dateOfBirth
		socialSecurityNumber
	}
`

export const WORK_EXPERIENCE_FRAGMENT = gql`
	fragment workExperienceFragment on WorkExperience {
		id
		facilityName
		position
		unitSpecialty
		floatSpecialty
		facilityType
		patientType
		city
		state
		country
		travelAssignment
		agency
		reasonForLeaving
		dutiesPerformed
		startDate
		endDate
		currentlyEmployed
		assignmentType
		supervisor
		mayContactSupervisor
		nurseRatio
		patientRatio
		bedsInUnit
		birthsPerMonth
		numberSuites
		dailyCensus
		chartingSoftwareUsed
	}
`

export const SALESFORCE_INTEGRATION_FRAGMENT = gql`
	fragment salesforceIntegrationFragment on SalesforceIntegration {
		id
		companyId
		businessDay
		weekending
	}
`

export const ONBOARDING_PART_FRAGMENT = gql`
	fragment onboardingPartFragment on OnboardingPart {
		id
		name
		description
	}
`
export const DIRECT_UPLOAD_FRAGMENT = gql`
	fragment directUploadFragment on DirectUpload {
		blobId
		headers
		preSignedUrl
		signedBlobId
		url
	}
`

export const WORKER_CERTIFICATION_FRAGMENT = gql`
	fragment workerCertificationFragment on WorkerCertification {
		id
		title
		licenseDiscipline
		licenseState
		licenseNumber
		compactLicense
		expirationDate
		certificationType
		createdAt
		licenseImages {
			id
			contentType
			imageUrls(size: "500x500")
			fileUrl
		}
	}
`

export const WORKER_REFERENCE_FRAGMENT = gql`
	fragment workerReferenceFragment on WorkerReference {
		id
		facilityName
		contactFullName
		contactFirstName
		contactLastName
		contactEmail
		contactPhone
		jobTitle
		refType
		relationship
		salutation
		state
		workedFrom
		workedTo
		city
	}
`
export const IMAGE_FILE_FRAGMENT = gql`
	fragment imageFileFragment on File {
		id
		fileUrl
		contentType
	}
`

export const FILE_FRAGMENT = gql`
	fragment fileFragment on File {
		id
		filename
		fileUrl
		contentType
	}
`

export const WORKER_EDUCATION_FRAGMENT = gql`
	fragment workerEducationFragment on WorkerEducation {
		id
		schoolName
		major
		state
		city
		country
		degreeName
		degreeDate
	}
`

export const LICENSE_IMAGES_FRAGMENT = gql`
	fragment licenseImagesFragment on File {
		id
		fileUrl
		contentType
	}
`

export const WORKER_CETIFICATION_OVERVIEW_FRAGMENT = gql`
	fragment workerCertificationOverviewFragment on WorkerCertificationOverview {
		id
		workerCertifications {
			...workerCertificationFragment
		}
	}

	${WORKER_CERTIFICATION_FRAGMENT}
`

export const WORKER_REFERENCE_OVERVIEW_FRAGMENT = gql`
	fragment workerReferenceOverviewFragment on WorkerReferenceOverview {
		id
		workerReferences {
			...workerReferenceFragment
		}
	}

	${WORKER_REFERENCE_FRAGMENT}
`

export const WORKER_EDUCATION_OVERVIEW_FRAGMENT = gql`
	fragment workerEducationOverviewFragment on WorkerEducationOverview {
		id
		workerEducations {
			...workerEducationFragment
		}
	}

	${WORKER_EDUCATION_FRAGMENT}
`

export const WORK_EXPERIENCE_OVERVIEW_FRAMENT = gql`
	fragment workExperienceOverview on WorkExperienceOverview {
		id
		discipline
		primarySpecialty
		secondarySpecialty
		yearsOfExperience
		workExperiences {
			...workExperienceFragment
		}
	}

	${WORK_EXPERIENCE_FRAGMENT}
`

export const WORKER_ONBOARDING_PART_INTEGRATION_FRAGMENT = gql`
	fragment workerOnboardingPartIntegrationFragment on WorkerOnboardingPartIntegration {
		... on UserTermsOfService {
			id
			agreedAt
			termsOfService {
				id
				url
				version
			}
		}
		... on ResumeOnboarding {
			id
			worker {
				resumes {
					blobId
					id
					filename
					fileUrl
					contentType
					thumbnails
					imageUrls(size: ["100x100", "200x200", "500x500"])
				}
			}
		}
		... on SpecialtyOnboarding {
			id
			workerSpecialties {
				category
				id
				isPrimary
				completedChecklist
				specialty
				specialtyLongName
				workerSkillChecklistSections {
					id
					name
					workerSkillChecklists {
						frequency
						proficiency
						question
						id
					}
				}
			}
		}
		... on WorkingPreferredLocationOnboarding {
			id
			worker {
				id
				availableStartDate
			}
			workingPreferredLocations {
				id
				preferredWorkingState
				preferredWorkingCity
			}
		}

		... on WorkerReferenceOnboarding {
			id
			workerReferences {
				id
				facilityName
				contactEmail
				contactFullName
				contactFirstName
				contactLastName
				contactPhone
				jobTitle
				refType
				relationship
				salutation
				state
				city
				workedFrom
				workedTo
			}
		}
	}
`

export const WORKER_ONBOARDING_PART_FRAGMENT = gql`
	fragment workerOnboardingPartFragment on WorkerOnboardingPart {
		id
		integrationConfigurations
		integrationObject
		state
		onboardingPart {
			id
			name
		}
		integration {
			...workerOnboardingPartIntegrationFragment
		}
	}

	${WORKER_ONBOARDING_PART_INTEGRATION_FRAGMENT}
`

export const REFERRED_TRAVELER_FRAGMENT = gql`
	fragment referredTravelerFragment on ReferredTraveler {
		id
		firstName
		lastName
		email
		phone
		relationship
		discipline
	}
`

export const RECRUITER_FRAGMENT = gql`
	fragment recruiterFragment on Recruiter {
		companyId
		email
		id
		name
		phone
		title
		changePasswordRequired
	}
`

export const JOB_APPLICANT_FRAGMENT = gql`
	fragment jobApplicantFragment on JobApplicant {
		createdAt
		id
		status
		updatedAt
		stage
		stageChangedAt
		translatedStatus
		job {
			...jobFragment
		}
		worker {
			...workerFragment
		}
	}
	${WORKER_FRAGMENT}
	${JOB_FRAGMENT}
`

export const WORKED_SHIFT_FRAGMENT = gql`
	fragment workedShiftFragment on WorkedShift {
		id
		workDate
		taskExternalId

		startTime
		endTime

		firstBreakStartTime
		firstBreakEndTime

		secondBreakStartTime
		secondBreakEndTime
	}
`

export const WORKED_EXPENSE_FRAGMENT = gql`
	fragment workedExpenseFragment on WorkerExpense {
		id
		category
		amount
		notes
		receiptImages {
			id
			filename
			fileUrl
		}
	}
`

export const WORKED_TIMESHEET_FRAGMENT = gql`
	fragment workedTimesheetFragment on WorkedTimesheet {
		id
		notes
		startDate
		endDate
		preEmploymentHours
		status
		workedShiftsOverview
	}
`

export const RECRUITER_WORKER_ONBOARDING_PART_INTEGRATION_FRAGMENT = gql`
	fragment workerOnboardingPartIntegrationFragment on WorkerOnboardingPartIntegration {
		... on WorkerCertificationOverview {
			...workerCertificationOverviewFragment
		}

		... on WorkExperienceOverview {
			...workExperienceOverview
		}

		... on WorkerReferenceOverview {
			...workerReferenceOverviewFragment
		}

		... on WorkerEducationOverview {
			...workerEducationOverviewFragment
		}

		... on UserTermsOfService {
			id
			agreedAt
			termsOfService {
				id
				url
				version
			}
		}

		... on CombinationOnboardingPart {
			id
			workerAddress {
				...workerAddressFragment
			}

			workingPreferredLocations {
				id
				preferredWorkingState
			}
		}
	}

	${WORKER_CETIFICATION_OVERVIEW_FRAGMENT}
	${WORK_EXPERIENCE_OVERVIEW_FRAMENT}
	${WORKER_REFERENCE_OVERVIEW_FRAGMENT}
	${WORKER_EDUCATION_OVERVIEW_FRAGMENT}
	${WORKER_ADDRESS_FRAGMENT}
`

export const RECRUITER_WORKER_ONBOARDING_PART_FRAGMENT = gql`
	fragment workerOnboardingPartFragment on WorkerOnboardingPart {
		id
		integrationConfigurations
		integrationObject
		state
		integration {
			...workerOnboardingPartIntegrationFragment
		}
	}

	${RECRUITER_WORKER_ONBOARDING_PART_INTEGRATION_FRAGMENT}
`

export const WORKER_ASSIGNMENT_FRAGMENT = gql`
	fragment workerAssignmentFragment on WorkerAssignment {
		createdAt
		endDate
		facilitySigned
		id
		isCurrentAssignment
		isSpecialWorkWeek
		job {
			...jobFragment
		}
		worker {
			...workerFragment
		}
		shiftTypes
		startDate
		status
		translatedStatus
		updatedAt
	}
	${JOB_FRAGMENT}
	${WORKER_FRAGMENT}
`
