export const COLECTIONS = {
	company_configs: 'company_configs',
	ui_states: 'ui_states',
	apply_forms: 'apply_forms',

	profile_infos: 'profile_infos',
	profile_licenses: 'profile_licenses',
	profile_works: 'profile_works',
	profile_references: 'profile_references',
	profile_educations: 'profile_educations',

	activations: 'activations',
	activation_licenses: 'activation_licenses',
	activation_experiences: 'activation_experiences',
	activation_references: 'activation_references',
	activation_verifications: 'activation_verifications',

	time_card_current: 'time_card_current',
	time_card_detail: 'time_card_detail',
	time_cards: 'time_cards',
}

export const REIMBURSEMENTS_CONST = '_reimbursements'
