import React from 'react'
import { Helmet } from 'react-helmet'

export const Meta = () => {
	const tagJTP = process.env.REACT_APP_COMPANY_HOST
	const nameCompany = process.env.REACT_APP_COMPANY_NAME

	const tags = [
		<meta key="1" property="og:title" content={nameCompany} />,
		<meta key="2" name="twitter:title" content={nameCompany} />,
		<meta key="3" property="og:description" content={'Responsive, friendly recruiters that listen'} />,
		<meta key="4" name="twitter:description" content={'Responsive, friendly recruiters that listen'} />,
		<link key="5" rel="canonical" href={`${window.location.origin}/assets/${tagJTP}/app-logo.png`} />,
		<meta key="6" property="og:image" content={`${window.location.origin}/assets/${tagJTP}/app-logo.png`} />,
		<meta key="7" property="twitter:image:src" content={`${window.location.origin}/assets/${tagJTP}/app-logo.png`} />,
	].filter(Boolean)

	if (tags?.length === 0) {
		return null
	}

	return <Helmet>{tags}</Helmet>
}
