import { apolloClient, GET_RECRUITER_STATISTICS_QUERY, RECRUITER_SEARCH_WORKERS_QUERY } from '~/common/apollo'
import { action, computed, observable, store } from '~/common/mobx.decorator'
import { authStore } from '~/stores'
import { find, isNil, remove } from 'lodash'

const GetRecentTraveler = () => {
	let strData = localStorage.getItem('recentTravelersData')
	let recentData = JSON.parse(strData)
	let recentTravelers = recentData && recentData[authStore?.id]

	if (recentTravelers === undefined) {
		return []
	}

	return recentTravelers
}

@store()
class ViewAsTravelerStore {
	@observable prevActiveTabValue = ''
	@observable activeTabValue = 'OVERVIEW'
	@observable statistics
	@observable travelers = []
	@observable recentTravelers = []
	@observable activeTabIndex = 0
	@observable workerId = null

	@computed
	get slideDirection() {
		return this.prevActiveTabValue > this.activeTabValue ? 'left' : 'right'
	}

	@computed
	get recentTravelersDataKey() {
		const getId = !isNil(localStorage.getItem('recentTravelersData')) && Object.keys(JSON.parse(localStorage.getItem('recentTravelersData')))?.[0]

		return getId === authStore.id
	}

	@action
	changeActiveTabValue = (tabValue) => {
		if (this.activeTabValue === tabValue) {
			return
		}
		// this.prevActiveTabIndex = -1;
		this.activeTabValue = tabValue
	}

	@action
	fetchStatistics = async () => {
		const response = await apolloClient.query({
			query: GET_RECRUITER_STATISTICS_QUERY,
		})
		this.statistics = response.data.recruiterStatistics
	}

	@action
	changeActiveTabIndex = (tabIndex) => {
		if (this.activeTabIndex === tabIndex || tabIndex < 0 || tabIndex > 2) {
			return
		}

		this.prevActiveTabIndex = -1
		this.activeTabIndex = tabIndex
	}

	fetchTravelers = async (keyword) => {
		const response = await apolloClient.query({
			query: RECRUITER_SEARCH_WORKERS_QUERY,
			variables: {
				keyword: keyword,
			},
		})

		if (response?.errors?.length > 0) {
			this.travelers = []
			return
		}

		this.travelers = response?.data?.recruiterSearchWorkers?.nodes
	}

	loadRecentTravelers = () => {
		this.recentTravelers = GetRecentTraveler()
	}

	updateRecentTravelers = (newValue) => {
		if (!newValue?.id) {
			return
		}
		let strData = localStorage.getItem('recentTravelersData')
		let recentData = {}
		var parsedData = JSON.parse(strData)
		if (parsedData !== null) {
			recentData = parsedData
		}
		let recentTravelers = recentData && recentData[authStore?.id]

		if (recentTravelers == null || recentTravelers === undefined) {
			recentTravelers = [newValue]
		} else {
			if (!find(recentTravelers, { id: newValue?.id })) {
				recentTravelers.unshift(newValue)
			}

			if (recentTravelers.length > 5) {
				recentTravelers = recentTravelers.slice(0, 5)
			}
		}

		recentData[authStore.id] = recentTravelers
		localStorage.setItem('recentTravelersData', JSON.stringify(recentData))
	}

	@action
	setWorkerId = (id) => {
		this.workerId = id
	}

	sortRecentTravelers = (workerID) => {
		let strData = localStorage.getItem('recentTravelersData')
		let recentData = {}
		var parsedData = JSON.parse(strData)
		if (parsedData !== null) {
			recentData = parsedData
		}
		let recentTravelers = recentData && recentData[authStore?.id]
		if (recentTravelers == null || recentTravelers === undefined) {
			return
		}

		var currentValue = {}
		currentValue = find(recentTravelers, { id: workerID })
		remove(recentTravelers, { id: workerID })
		recentTravelers.unshift(currentValue)

		recentData[authStore.id] = recentTravelers
		localStorage.setItem('recentTravelersData', JSON.stringify(recentData))
	}
}

export const viewAsTravelerStore = new ViewAsTravelerStore()
