import { CANDIDATE_TABS } from '~/common/constants/tab.constant'

export const PATHS = {
	// COMMON
	common: {
		home: '/',
		reset: '/reset',
		signin: '/signin',
		errors: '/errors',
		change_password: '/change_password',
		api: '/api',
		graphql: '/graphql',
		signup: '/signup',
		tracking: '/tracking',
		maintenance: '/maintenance',
	},
	// Recruiter Portal
	recruiter: {
		home: '/home',
		view_as_candidate: '/view_as_candidate',
		candidates: '/candidates',
		find_job: '/find_job',
		profile: '/profile',
		user_profile: '/user_profile',
		my_jobs: '/my_jobs',
		activation: '/activation',
		timecards: '/timecards',
		popular_cities: '/popular_cities',
		suggested_jobs: '/suggested_jobs',
		recruiter_jobs: '/recruiter_jobs',
		explore_matches: '/page/explore_matches',
		trending_jobs: '/page/trending_jobs',
		urgent_needs: '/page/urgent_needs',
		last_viewed: '/page/last_viewed',
		assigments: '/assigments',
		report: '/report',
		jobs: '/jobs',
		toas: '/toas',
		job_info: '/job_info',
		job_applicants: '/job_applicants',
		candidates_assignments: `/candidates/${CANDIDATE_TABS.on_assignment}`,
		candidates_submission: `/candidates/${CANDIDATE_TABS.submission}`,
		candidates_matches: `/candidates/${CANDIDATE_TABS.matches}`,
		candidates_pre_submissions: `/candidates/${CANDIDATE_TABS.pre_submission}`,
	},

	external: {
		jtp_support: 'https://www.jacksontherapy.com/about-us/contact',
		jnp_support: 'https://www.jacksonnursing.com/contact/',
	},
}
