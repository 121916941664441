import { eventClient, events } from '@opus/web.core.lib.event-tracking'
import axios from 'axios'
import { apolloClient, VERIFY_RECRUITER_QUERY } from '~/common/apollo'
import { EVENTS, ONE_DAY } from '~/common/constants'
import { captureException } from '~/common/helpers'
import { store, computed, action, observable, persist, event } from '~/common/mobx.decorator'
import { join } from 'lodash'
import { viewAsTravelerStore } from '~/features/recruiter-view-as-traveler/recruiter-view-as-traveler.store'
import { onboardingOverviewStore } from '~/features/recruiter-view-as-traveler/onboarding-progress/onboarding-progress.store'

const matchAnonymousEvents = async () => {
	// ONLY CALL THIS FUNCTION AFTER setUserId & setUserProperties
	try {
		const anonymousEvents = await window.host.getUserEvents()
		const anonymousId = await window.host.getId()

		const searchEvents = anonymousEvents
			.filter((event) => event.name === 'ANONYMOUS_SEARCH')
			.map(
				(event) =>
					new events.AnonymousSearchSuccessEvent({
						...event.data,
						createdAt: event.createdAt?.seconds * 1000 || new Date().getTime(),
						metadata: {
							ip: event.ip,
							user_agent: event.userAgent,
							anonymous_id: anonymousId,
						},
					})
			)

		searchEvents.forEach((event) => eventClient.logEvent(event))
		await window.host.archiveUserEvents()
		// IN CASE: You want to clean up all archived events
		// Please call: window.host.cleanUserEvents()
	} catch (error) {
		console.debug(error)
	}
}

class ProfileItem {
	@persist @observable id
	@persist @observable name
	@persist @observable workAuthorized
}

@store({ persist: true })
class AuthStore {
	@persist @observable token = ''
	@persist @observable maintenanceMessage = ''
	@persist @observable expiresAt = 0
	@persist('object', ProfileItem) @observable profile

	@observable currentJob = null

	@action
	changeActiveTabIndex = (tabIndex) => {
		if (this.activeTabIndex === tabIndex) {
			return
		}

		this.prevActiveTabIndex = -1
		this.activeTabIndex = tabIndex
	}

	@computed
	get recruiter() {
		return this.profile?.recruiter
	}

	@computed
	get authorization() {
		return !this.token || Date.now() > this.expiresAt ? '' : this.token
	}

	@computed
	get features() {
		return [
			'recruiter-home',
			'recruiter-view-as-candidate',
			'recruiter-assignments',
			'recruiter-job-applicants',
			'recruiter-jobs',
			'recruiter-job-info',
			'recruiter-candidates',
			'recruiter-toas',
			'recruiter-report',
			'recruiter-profile',
			'care-jobs',
			'care-activation',
			'care-profile',
			'care-popular-cities',
			'care-find-job',
			'care-suggested-jobs',
			'care-my-jobs',
			'common-change-password',
		]
	}

	@computed
	get enableHourlySearch() {
		return this.profile?.company?.companyConfig?.enableHourlySearch || false
	}

	@computed
	get id() {
		return this.authorization && this.profile?.id
	}

	@computed
	get firstName() {
		return this.profile?.name.split(' ')?.[0] ?? ''
	}

	@computed
	get lastName() {
		const [, ...rest] = this.profile?.name.split(' ')
		return join(rest, ' ') ?? ''
	}

	@computed
	get name() {
		return this.profile?.name
	}

	@computed
	get email() {
		return this.profile?.email
	}

	@computed
	get role() {
		return this.profile?.title
	}

	@computed
	get fullName() {
		return this.name
	}

	@computed
	get activated() {
		return this.profile?.workAuthorized
	}

	@action
	changeProfile = async (profile) => {
		if (this.profile && profile && this.profile?.id === profile?.id && profile.workAuthorized === true && this.activated !== profile?.workAuthorized) {
			eventClient.logEvent(new events.OnboardingWorkerAuthorizedSuccessEvent())
		}

		this.profile = profile

		if (profile) {
			eventClient.setUserId(profile.id)
			eventClient.setUserProperties({
				id: profile.id,
				email: profile.email,
				first_name: this.firstName,
				last_name: this.lastName,
				company_id: process.env.REACT_APP_COMPANY_ID,
				work_authorized: profile.workAuthorized,
			})

			// ONLY CALL THIS FUNCTION AFTER setUserId & setUserProperties
			await matchAnonymousEvents()
		}

		// notifyStore.signal.push(() => {
		// 	notifyStore.signal.sendTags(profile)
		// 	notifyStore.signal.setExternalUserId(profile?.id)
		//})

		// Update LiveChat Info
		if (window.LC_API && typeof window.LC_API.set_visitor_name === 'function') {
			window.LC_API.set_visitor_name(this.name)
			window.LC_API.set_visitor_email(profile?.email)
		}
	}

	@action
	changeToken = async (token = '', duration = ONE_DAY * 30) => {
		this.token = token
		this.expiresAt = token ? Date.now() + duration : -1

		if (token) {
			axios.defaults.headers.common['Auth-Token'] = token
		} else {
			delete axios.defaults.headers.common['Auth-Token']
		}

		// if (!token) {
		// 	notifyStore.signal.push(() => {
		// 		notifyStore.signal.setExternalUserId(null)
		// 	})
		// }
	}

	@action
	verifyToken = async () => {
		if (!this.authorization) {
			await this.changeToken('')
			return
		}

		try {
			const {
				data: { recruiterToken },
			} = await apolloClient.query({
				query: VERIFY_RECRUITER_QUERY,
				variables: { token: this.authorization },
			})
			const { token, recruiter } = recruiterToken

			this.profile = recruiter
			await this.changeToken(token)
			await this.changeProfile(recruiter)
			console.log('🚀 ~ file: auth.store.js:199 ~ AuthStore ~ verifyToken= ~ recruiter:', recruiter)

			return recruiter
		} catch (error) {
			captureException('Verify token', error)
			await this.changeToken('')
		}
	}

	@action
	setMaintenanceMessage = (message) => {
		this.maintenanceMessage = message
	}

	@event(EVENTS.authStore.logout)
	logout() {
		this.changeToken('')
		eventClient.setUserId(null)
		eventClient.setUserProperties(null)
		viewAsTravelerStore.setWorkerId(null)
		onboardingOverviewStore.clearWorker()
	}
}

export const authStore = new AuthStore()
