import { apolloClient, GET_RECRUITER_WORKER_ONBOARDING_OVERVIEW_QUERY, GET_RECRUITER_WORKER_QUERY } from '~/common/apollo'
import { action, observable, store } from '~/common/mobx.decorator'

@store()
class OnboardingOverviewStore {
	@observable onboardingParts = []
	@observable percentageCompleted = 0
	@observable worker = {}
	@observable found = true

	@action
	fetchUserOnboardingOverview = async (workerId) => {
		if (!workerId) return
		const response = await apolloClient.query({
			query: GET_RECRUITER_WORKER_ONBOARDING_OVERVIEW_QUERY,
			variables: {
				workerId: workerId,
			},
		})

		if (response?.errors?.length > 0) {
			return
		}

		this.onboardingParts = response?.data?.recruiterWorkerOnboardingOverview?.onboardingParts
		this.percentageCompleted = response?.data?.recruiterWorkerOnboardingOverview?.percentageCompleted
	}

	fetchWorker = async (workerId) => {
		const response = await apolloClient.query({
			query: GET_RECRUITER_WORKER_QUERY,
			variables: {
				workerId: workerId,
			},
		})

		if (response?.errors?.length > 0) {
			this.found = false
			return
		}

		this.worker = response?.data?.recruiterWorker
		this.found = true
	}

	clearWorker = () => {
		this.worker = {}
	}
}

export const onboardingOverviewStore = new OnboardingOverviewStore()
