import gql from 'graphql-tag'

export const GET_APP_INIT_QUERY = gql`
	query getAppInit($workerId: String!) {
		appInit(workerId: $workerId) {
			companyConfig {
				livechatIntegration {
					accountId
				}
			}
		}
	}
`
