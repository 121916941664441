import { createMuiTheme } from '@material-ui/core'

export const opusTheme = createMuiTheme({
	overrides: {
		MuiDrawer: {
			paperAnchorDockedLeft: {
				borderRight: '1px solid #F4F5F4',
			},
		},
		MuiListItem: {
			root: {
				color: '#868686',
				fontSize: '14px',
				fontWeight: 800,
				letterSpacing: '0.1px',
				'&.Mui-selected': {
					backgroundColor: 'transparent',
				},
			},
		},
		MuiSlider: {
			track: {
				backgroundColor: '#0083C9',
			},
			rail: {
				backgroundColor: '#D1D1D1',
			},
		},
		MuiFormHelperText: {
			root: {
				fontSize: '13px',
				fontWeight: 600,
			},
		},

		MuiFormLabel: {
			root: {
				fontWeight: '600 !important',
				color: '#7E7E80',
				whiteSpace: 'nowrap',
				'&$focused': {
					color: '#7E7E80',
				},
				fontSize: '12px',
			},
		},
		MuiFormControl: {
			root: {
				fontSize: '12px',
				fontStyle: 'normal',
				fontWeight: 600,
				letterSpacing: '0.1px',
			},
		},
		MuiFormControlLabel: {
			label: {
				fontWeight: 500,
				fontSize: '12px',
				lineHeight: '18px',
				letterSpacing: '0.2px',
				color: '#7E7E80',
			},
		},
		MuiInputBase: {
			root: {
				fontWeight: 400,
				fontSize: '16px',
				color: '#333335',
			},
		},
		MuiInput: {
			underline: {
				borderBottomColor: '#E6E6E6',
			},
		},
		MuiInputLabel: {
			shrink: {
				transform: 'translate(0, 1.5px) scale(0.86)',
			},
		},
		MuiBackdrop: {
			root: {
				backgroundColor: 'transparent !important',
			},
		},
		MuiDialog: {
			container: {
				backgroundColor: 'rgba(0, 0, 0, 0.5)',
			},
		},
		MuiButtonGroup: {
			groupedOutlinedPrimary: {
				'&:hover': {
					borderColor: '#99E1E1',
				},
			},
		},
		MuiButton: {
			root: {
				borderRadius: '5px',
			},
			outlinedPrimary: {
				border: '2px solid #C2DAF0',
				backgroundColor: '#FFFFFF',
				'&:hover': {
					border: '2px solid #EDF7FF',
				},
			},
			containedPrimary: {
				color: '#F8F8F8',
				backgroundColor: '#2F2E99',
				boxShadow: 'none',
				border: 'none',
			},

			sizeLarge: {
				height: '44px',
			},
			sizeSmall: {
				height: '32px',
			},
		},
		MuiTab: {
			root: {
				textTransform: 'unset',
			},
		},
		MuiDivider: {
			root: {
				backgroundColor: '#E6E6E6',
			},
		},
		MuiStepLabel: {
			label: {
				color: '#255E8E !important',
			},
		},
		MuiStepIcon: {
			active: {
				color: '#DDEFFF !important',
			},
			completed: {
				color: '#DDEFFF !important',
			},
		},
		MuiRadio: {
			root: {
				color: '#255E8E',
			},
		},
		MuiPickersDay: {
			root: {
				fontWeight: 'normal',
			},
		},
		MuiChip: {
			root: {
				backgroundColor: '#F6FBFF',
				border: '1px solid #C2DAF0',
			},
			deleteIcon: {
				color: '#255E8E',
			},
		},
		MuiCheckbox: {
			root: {
				color: '#255E8E',
			},
			colorSecondary: {
				color: '#255E8E !important',
			},
		},
	},
	spacing: 8,
	palette: {
		primary: {
			main: '#2F2E99',
			sub: '#0083C9',
		},
		secondary: {
			main: '#405B23',
			sub: '#869B6F',
		},
		background: {
			default: '#ffffff',
			app: {
				dark: '#000000',
				light: '#FFFFFF',
			},
			element: {
				dark: '#C2DAF0',
				medium: '#DDEFFF',
				light: '#EDF7FF',
				veryLight: '#F6FBFF',
				footerSideBar: '#255E8E',
			},
		},
		content: {
			veryDark: '#333335',
			dark: '#7E7E80',
			medium: '#D1D1D1',
			light: '#E6E6E6',
			veryLight: '#F8F8F8',
		},
		status: {
			success: {
				dark: '#309102',
				medium: '#3BB700',
				light: '#A5FB7C',
			},
			warning: {
				dark: '#DE62A',
				medium: '#FF9B04',
				light: '#FF49F',
			},
			error: {
				dark: '#B9431F',
				medium: '#FF2727',
				light: '#FF9090',
			},
		},
		hyperlink: {
			dark: '#0C4AC2',
			medium: '#2C6EEE',
			light: '#9ABCFF',
		},
		other: {
			dark: '#9F8762',
			medium: '#CEB692',
			light: '#EEE3D2',
		},

		error: {
			main: '#fe7d74',
		},
		text: {
			primary: '#000000',
			secondary: '#03606A',
			tertiary: '#7E7E80',
			disabled: '#333335',
			blue: '#1C5CDB',
			hint: '#868686',
		},

		action: {
			active: '#868686',
			hover: 'rgba(0, 0, 0, 0.04)',
			selected: 'rgba(0, 0, 0, 0.08)',
			disabled: 'rgba(0, 0, 0, 0.26)',
			disabledBackground: 'rgba(0, 0, 0, 0.12)',
		},
		divider: '#E6E6E6',
	},
	colors: {
		primary: {
			main: '#2F2E99',
			sub: '#0083C9',
		},
		secondary: {
			main: '#FF4405',
			sub: '#869B6F',
		},
		background: {
			app: {
				dark: '#000000',
				light: '#FFFFFF',
			},
			element: {
				dark: '#C2DAF0',
				medium: '#DDEFFF',
				light: '#EDF7FF',
				veryLight: '#F6FBFF',
				footerSideBar: '#255E8E',
			},
		},
		content: {
			veryDark: '#333335',
			dark: '#7E7E80',
			medium: '#D1D1D1',
			light: '#E6E6E6',
			veryLight: '#F8F8F8',
		},
		status: {
			success: {
				dark: '#309102',
				medium: '#3BB700',
				light: '#A5FB7C',
			},
			warning: {
				dark: '#DE62A',
				medium: '#FF9B04',
				light: '#FF49F',
			},
			error: {
				dark: '#B9431F',
				medium: '#FF2727',
				light: '#FF9090',
			},
		},
		hyperlink: {
			dark: '#0C4AC2',
			medium: '#2C6EEE',
			light: '#9ABCFF',
		},
		other: {
			dark: '#9F8762',
			medium: '#CEB692',
			light: '#EEE3D2',
		},
	},

	shape: {
		borderRadius: 10,
	},
	borders: ['unset', 'solid 1px #e5e5e5', 'solid 1px rgba(130, 206, 207, 0.1)', 'solid 1px #82CECE'],
	typography: {
		fontFamily: ['Inter'],
		h1: {
			fontSize: '56px',
			lineHeight: '72px',
			fontWeight: 700,
			letterSpacing: '0.2px',
		},
		h2: {
			fontSize: '44px',
			lineHeight: '56px',
			fontWeight: 700,
			letterSpacing: '0.2px',
		},
		h3: {
			fontSize: '32px',
			lineHeight: '40px',
			fontWeight: 700,
			letterSpacing: '0.2px',
		},
		h4: {
			fontSize: '26px',
			lineHeight: '32px',
			fontWeight: 700,
			letterSpacing: '0.2px',
		},
		h5: {
			fontSize: '20px',
			lineHeight: '26px',
			fontWeight: 700,
			letterSpacing: '0.1px',
		},
		h6: {
			fontSize: '18px',
			lineHeight: '24px',
			fontWeight: 700,
			letterSpacing: '0.1px',
		},

		title1: {
			fontSize: '16px',
			lineHeight: '20px',
			fontWeight: 700,
			letterSpacing: '0.1px',
		},
		title2: {
			fontSize: '14px',
			lineHeight: '18px',
			fontWeight: 700,
			letterSpacing: '0.1px',
		},
		title3: {
			fontSize: '12px',
			lineHeight: '18px',
			fontWeight: 700,
			letterSpacing: '0.1px',
		},

		subtitle1: {
			fontSize: '16px',
			lineHeight: '20px',
			fontWeight: 600,
			letterSpacing: '0.1px',
		},
		subtitle2: {
			fontSize: '14px',
			lineHeight: '18px',
			fontWeight: 600,
			letterSpacing: '0.2px',
		},
		subtitle3: {
			fontSize: '12px',
			lineHeight: '16px',
			fontWeight: 600,
			letterSpacing: '0.1px',
		},
		body1: {
			fontSize: '16px',
			lineHeight: '24px',
			fontWeight: 400,
			letterSpacing: '0.3px',
		},
		body2: {
			fontSize: '14px',
			lineHeight: '20px',
			fontWeight: 400,
			letterSpacing: '0.2px',
		},
		body3: {
			fontSize: '12px',
			lineHeight: '18px',
			fontWeight: 400,
			letterSpacing: '0.1px',
		},
		price: {
			fontSize: '18px',
			lineHeight: '24px',
			fontWeight: 600,
			letterSpacing: '0.2px',
			color: '#333335',
		},
		button: {
			fontSize: '15px',
			lineHeight: '20px',
			fontWeight: 600,
			letterSpacing: '0px',
			textTransform: 'uppercase,',
		},
		caption: {
			fontSize: '14px',
			lineHeight: '18px',
			fontWeight: 800,
			letterSpacing: '0.4px',
		},
		ghostButton: {
			fontSize: '16px',
			lineHeight: '18px',
			fontWeight: 400,
			letterSpacing: '0.2px',
		},
		footNote: {
			fontSize: '10px',
			lineHeight: '15px',
			fontWeight: 600,
			letterSpacing: '0.1px',
		},
	},
})
