import { unflatten } from 'flat'
import { get, isEmpty, keyBy, mapValues } from 'lodash'

export const toFormErrors = (error, baseField = '__ERROR') => {
	const errors = unflatten(
		mapValues(
			keyBy(error?.graphQLErrors, (value) => get(value, 'extensions.attribute', baseField)),
			'message'
		)
	)

	if (isEmpty(errors)) {
		return { [baseField]: error?.message }
	}

	return errors
}
