import { style } from '@material-ui/system'
import { css } from 'styled-components'

export const whiteSpace = style({
	prop: 'whiteSpace',
	cssProperty: 'whiteSpace',
})

export const textTransform = style({
	prop: 'textTransform',
	cssProperty: 'textTransform',
})

export const transform = style({ prop: 'transform', cssProperty: 'transform' })

export const opacity = style({ prop: 'opacity', cssProperty: 'opacity' })

export const ellipsis = css`
	overflow: hidden;
	text-overflow: ellipsis;
`
