export const COMPANY = {
	JNP: 'JNP',
	JTP: 'JTP',
	OPUS: 'OPUS',
}
export const COMPANY_PATH = {
	jnp: 'jnp',
	jtp: 'jtp',
	opus: 'opus',
}
