import { gql } from '@apollo/client'
import { RECRUITER_FRAGMENT } from '../apollo.fragment'

export const SIGNIN_SALESFORCE_RECRUITER_MUTATION = gql`
	mutation signInSalesforceRecruiter($jwtToken: String!, $companyId: String!) {
		signInSalesforceRecruiter(jwtToken: $jwtToken, companyId: $companyId) {
			authToken
			notificationChannel
			recruiter {
				...recruiterFragment
			}
		}
	}

	${RECRUITER_FRAGMENT}
`

export const SIGNIN_RECRUITER_MUTATION = gql`
	mutation signInRecruiter($email: String!, $password: String!, $recaptchaToken: String) {
		signInRecruiter(email: $email, password: $password, recaptchaToken: $recaptchaToken) {
			authToken
			notificationChannel
			recruiter {
				...recruiterFragment
			}
		}
	}

	${RECRUITER_FRAGMENT}
`

export const RECRUITER_CHANGE_PASSWORD = gql`
	mutation recruiterChangePassword($password: String!, $passwordConfirmation: String!) {
		recruiterChangePassword(password: $password, passwordConfirmation: $passwordConfirmation) {
			success
		}
	}
`

export const RECRUITER_PROFILE_CHANGE_PASSWORD = gql`
	mutation recruiterChangePassword($oldPassword: String, $password: String!, $passwordConfirmation: String!) {
		recruiterChangePassword(oldPassword: $oldPassword, password: $password, passwordConfirmation: $passwordConfirmation) {
			success
		}
	}
`

export const RECRUITER_UPDATE_PROFILE = gql`
	mutation recruiterUpdateRecruiter($id: String!, $firstName: String, $lastName: String, $email: String, $title: String) {
		recruiterUpdateRecruiter(id: $id, firstName: $firstName, lastName: $lastName, email: $email, title: $title) {
			id
		}
	}
`

export const SIGNOUT_RECRUITER_MUTATION = gql`
	mutation signOutRecruiter {
		signOutRecruiter {
			success
		}
	}
`
