import { createMuiTheme } from '@material-ui/core'

export const recruiterTheme = createMuiTheme({
	overrides: {
		fontFamily: 'Open Sans',
		MuiDrawer: {
			paperAnchorDockedLeft: {
				borderRight: '1px solid #F4F5F4',
			},
		},
		MuiListItem: {
			root: {
				color: '#868686',
				fontSize: '14px',
				fontWeight: 800,
				letterSpacing: '0.1px',
				fontFamily: 'Open Sans',
				'&.Mui-selected': {
					backgroundColor: 'transparent',
				},
			},
		},
		MuiFormLabel: {
			root: {
				color: '#0E3F6C',
				whiteSpace: 'nowrap',
				fontWeight: 800,
				'&$focused': {
					color: '#0E3F6C',
				},
			},
		},

		MuiFormHelperText: {
			root: {
				fontSize: '12px',
				fontFamily: 'Open Sans',
				lineHeight: '18px',
				fontWeight: 400,
				letterSpacing: '0.1px',
				color: '#FF2727 !important',
				marginTop: '6px',
			},
		},

		MuiFormControlLabel: {
			label: {
				fontWeight: 500,
				fontSize: '14px',
				lineHeight: '18px',
				letterSpacing: '0.2px',
				color: '#0E3F6C',
			},
		},
		MuiInputBase: {
			root: {
				color: '#24444E',
				fontWeight: 400,
				fontSize: '14px',
			},
		},
		MuiInputLabel: {
			shrink: {
				transform: 'translate(0, 1.5px) scale(0.86)',
			},
		},
		MuiBackdrop: {
			root: {
				backgroundColor: 'transparent !important',
			},
		},
		MuiDialog: {
			container: {
				backgroundColor: 'rgba(0, 0, 0, 0.5)',
			},
		},
		MuiButtonGroup: {
			groupedOutlinedPrimary: {
				'&:hover': {
					borderColor: '#99E1E1',
				},
			},
		},
		MuiButton: {
			contained: {
				boxShadow: 'none',
			},
			outlined: {
				border: '2px solid #E5E5E5',
				color: '#7E7E80',
				backgroundColor: '#FFFFFF',
				'&:hover': {
					border: '2px solid #CCC',
				},
			},
			outlinedPrimary: {
				backgroundColor: '#255E8E',
				'&:hover': {},
			},
			containedPrimary: {
				color: '#FFF',
				backgroundColor: '#255E8E',
				'&:hover': {
					backgroundColor: '',
					boxShadow: 'none',
				},
			},

			sizeLarge: {
				height: '48px',
			},
			sizeSmall: {
				height: '32px',
			},
		},
		MuiTabs: {
			indicator: {
				height: '2px',
			},
		},
		MuiTab: {
			root: {
				textTransform: 'unset',
			},
			wrapper: {
				fontSize: '16px',
				lineHeight: '24px',
				fontWeight: 700,
				letterSpacing: '0.1px',
			},
		},
		MuiDivider: {
			root: {
				backgroundColor: '#99E1E1',
			},
		},
		MuiAutocomplete: {
			groupLabel: {
				color: '#7E7E80',
				fontSize: '16px',
			},
			groupUl: {
				paddingLeft: '16px',
			},
			option: {
				color: '#7E7E80',
				fontSize: '16px',
			},
		},
		MuiChip: {
			colorPrimary: {
				backgroundColor: '#F8F8F8',
				color: '#424242',
				border: '1px solid #E5E5E5',
			},
			deleteIconColorPrimary: {
				color: '#424242',
				'&:hover': {
					color: '#424242',
				},
				width: '14px',
				height: '14px',
				fontSize: '12px',
			},
			clickableColorSecondary: {
				backgroundColor: 'transparent',
				color: '#FF2727',
				border: '1px solid #FF2727',
				'&:hover': {
					backgroundColor: 'transparent',
				},
			},
			deleteIconColorSecondary: {
				color: '#FF2727',
				'&:hover': {
					color: '#FF2727',
				},
				width: '14px',
				height: '14px',
				fontSize: '12px',
			},
		},
	},
	spacing: 8,
	palette: {
		primary: {
			veryDark: '#424242',
			dark: '#737373',
			light: '#E3F8F4',
			main: '#255E8E',
			secondary: '#2D9CDB',
			tertiary: '#03606A',
			contrastText: '#ffffff',
		},
		secondary: {
			light: '#f8fcfc',
			main: '#82cecf',
		},

		text: {
			primary: '#000000',
			secondary: '#03606A',
			tertiary: '#7E7E80',
			disabled: '#AEAEAE',
			blue: '#1C5CDB',
			hint: '#868686',
		},
		background: {
			default: '#ffffff',
			paper: '#ffffff',
			light: 'rgba(130, 206, 206, 0.5)',
			app: {
				dark: '#000000',
				light: '#FFFFFF',
			},
		},
		content: {
			veryDark: '#424242',
			dark: '#737373',
			medium: '#D1D1D1',
			light: '#E6E6E6',
			veryLight: '#F8F8F8',
			borderAvatar: '#405B23',
		},

		status: {
			success: {
				dark: '#309102',
				medium: '#37AA00',
				light: '#A5FB7C',
			},
			warning: {
				dark: '#DE62A',
				medium: '#F29100',
				light: '#FF49F',
			},
			error: {
				dark: '#B9431F',
				medium: '#FF2727',
				light: '#FF9090',
			},
		},
		action: {
			active: '#868686',
			hover: 'rgba(0, 0, 0, 0.04)',
			selected: 'rgba(0, 0, 0, 0.08)',
			disabled: 'rgba(0, 0, 0, 0.26)',
			disabledBackground: 'rgba(0, 0, 0, 0.12)',
		},
		divider: 'rgba(0, 0, 0, 0.12)',
	},
	shape: {
		borderRadius: 10,
	},
	borders: ['unset', 'solid 1px #e5e5e5', 'solid 1px rgba(130, 206, 207, 0.1)', 'solid 1px #82CECE'],
	typography: {
		fontFamily: ['Open Sans'].join(', '),
		h6: {
			fontSize: '18px',
			lineHeight: '24px',
			fontWeight: 700,
			letterSpacing: '0.1px',
		},
		h5: { fontSize: '1.1875rem', fontWeight: 500 },
		h4: {
			fontSize: '26px',
			lineHeight: '32px',
			fontWeight: 700,
			letterSpacing: '0.2px',
		},
		h3: { fontSize: '1.875rem' },
		h2: { fontSize: '2rem' },
		h1: { fontSize: '2rem' },
		subtitle1: {
			fontSize: '16px',
			fontWeight: 500,
			lineHeight: '20px',
		},
		subtitle2: {
			fontSize: '14px',
			lineHeight: '18px',
			fontWeight: 600,
			letterSpacing: '0.2px',
		},
		subtitle3: {
			fontSize: '12px',
			lineHeight: '16px',
			fontWeight: 500,
			letterSpacing: '0.1px',
		},
		body1: {
			fontSize: '16px',
			lineHeight: '24px',
			fontWeight: 400,
			letterSpacing: '0.3px',
		},
		body2: {
			fontSize: '14px',
			lineHeight: '20px',
			fontWeight: 400,
			letterSpacing: '0.2px',
		},
		body3: {
			fontSize: '12px',
			lineHeight: '18px',
			fontWeight: 400,
			letterSpacing: '0.1px',
		},
		button: { fontSize: '0.9375rem' },
		caption: { fontSize: '0.8125rem' },
		title1: {
			fontSize: '16px',
			lineHeight: '24px',
			fontWeight: 700,
			letterSpacing: '0.1px',
		},
		title2: {
			fontSize: '14px',
			lineHeight: '18px',
			fontWeight: 700,
			letterSpacing: '0.1px',
		},
		title3: {
			fontSize: '12px',
			lineHeight: '18px',
			fontWeight: 700,
			letterSpacing: '0.1px',
		},

		footnoteNormal: {
			fontSize: '10px',
			lineHeight: '15px',
			fontWeight: 600,
			letterSpacing: '0.1px',
		},
	},
})
