export const EVENTS = {
	authStore: {
		logout: 'authStore/logout',
	},
	notifyStore: {
		fireError: 'notifyStore/fireError',
	},
	careStore: {
		updateHeader: 'careStore/updateHeader',
		updateHeaderNav: 'careStore/updateHeaderNav',
		updateBreadcumbs: 'careStore/updateBreadcumbs',
	},
	careHomeStore: {
		updateActivationOverall: 'careHomeStore/updateActivationOverall',
	},
}

export const ACTIONS = {
	appStore: {
		init: 'appStore/init',
	},

	authStore: {
		changeToken: 'authStore/changeToken',
	},

	careStore: {
		fetchWorkerDetail: 'careStore/fetchWorkerDetail',
	},

	activationTab: {
		fetchOnboardingOverview: 'activationTabStore/fetchOnboardingOverview',
	},

	careProfileStore: {
		handleSubmitInfo: 'careProfileStore/handleSubmitInfo',
		handleSubmitExperiences: 'careProfileStore/handleSubmitExperiences',
		handleSubmitLicenses: 'careProfileStore/handleSubmitLicenses',
		fetchWorkerDetail: 'careProfileStore/fetchWorkerDetail',
	},

	careTimeCardsStore: {
		fetchTimeCards: 'careTimeCardsStore/fetchTimeCards',
	},

	licenseStepStore: {
		handleSubmitLicense: 'licenseStepStore/handleSubmitLicense',
	},

	experienceStepStore: {
		handleSubmitExperiences: 'experienceStepStore/handleSubmitExperiences',
	},

	referenceStepStore: {
		handleSubmitReferences: 'referenceStepStore/handleSubmitReferences',
	},
}
