import { css } from 'styled-components'

export const notifyDialogStyle = css`
	.MuiDialog-paper {
		margin: 8px;

		> .MuiBox-root {
			min-width: 240px;
			max-width: 340px;

			.header {
				.MuiTypography-root {
					color: ${({ theme }) => theme.palette.primary.main};
				}
			}
		}
	}
`
