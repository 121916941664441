import { gql } from '@apollo/client'
import { REFERRED_TRAVELER_FRAGMENT } from '../apollo.fragment'

export const CREATE_REFERRED_TRAVELER_MUTATION = gql`
	mutation createReferredTravelerMutation(
		$email: String!
		$firstName: String!
		$lastName: String!
		$phone: String
		$discipline: String!
		$relationship: String!
	) {
		createReferredTraveler(email: $email, firstName: $firstName, lastName: $lastName, phone: $phone, discipline: $discipline, relationship: $relationship) {
			...referredTravelerFragment
		}
	}

	${REFERRED_TRAVELER_FRAGMENT}
`
