import { gql } from '@apollo/client'
import { FILE_FRAGMENT, WORKER_ONBOARDING_PART_FRAGMENT, RECRUITER_WORKER_ONBOARDING_PART_FRAGMENT } from '../apollo.fragment'

export const RECRUITER_UPDATE_WORKER_ONBOARDING_PART_MUTATION = gql`
	mutation recruiterUpdateWorkerOnboardingPart($id: String!, $workerId: String!, $integrationAttributes: JSON!) {
		recruiterUpdateWorkerOnboardingPart(id: $id, workerId: $workerId, integrationAttributes: $integrationAttributes) {
			...workerOnboardingPartFragment
		}
	}

	${RECRUITER_WORKER_ONBOARDING_PART_FRAGMENT}
`

export const UPDATE_WORKER_ONBOARDING_PART_MUTATION = gql`
	mutation recruiterUpdateWorkerOnboardingPart($workerId: String!, $id: String!, $integrationAttributes: JSON!) {
		recruiterUpdateWorkerOnboardingPart(workerId: $workerId, id: $id, integrationAttributes: $integrationAttributes) {
			...workerOnboardingPartFragment
		}
	}

	${WORKER_ONBOARDING_PART_FRAGMENT}
`

export const UPDATE_WORKER_RESUMES_MUTATION = gql`
	mutation updateWorkerResumes($id: String!, $signedBlobId: String!) {
		updateIntegrationWorker(id: $id, signedBlobId: $signedBlobId) {
			resume {
				...fileFragment
			}
		}
	}

	${FILE_FRAGMENT}
`

export const RECRUITER_UPDATE_WORKER_RESUMES_MUTATION = gql`
	mutation recruiterUpdateIntegrationWorker($id: String!, $signedBlobId: String!) {
		recruiterUpdateIntegrationWorker(id: $id, signedBlobId: $signedBlobId) {
			resume {
				...fileFragment
			}
		}
	}

	${FILE_FRAGMENT}
`
