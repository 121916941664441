import axios from 'axios'
import { nanoid } from 'nanoid'
import { captureException } from '~/common/helpers'
import { observable, action, store, computed, storage, persist } from '~/common/mobx.decorator'
import { authStore } from './auth.store'
import { COMPANY, COMPANY_PATH } from '~/common/constants/company.constant'
import { THEMES } from '~/themes'

axios.defaults.baseURL = `${process.env.REACT_APP_PROXY_API_URL}`
axios.defaults.headers['APP_ID'] = process.env.REACT_APP_PROXY_API_APP_ID
axios.defaults.headers['APP_SECRET'] = process.env.REACT_APP_PROXY_API_APP_SECRET

axios.interceptors.response.use(undefined, (error) => {
	captureException('Axios Client', error)
	return Promise.reject(error)
})

@store({ persist: true })
class AppStore {
	constructor() {
		storage.ready(() => {
			this.ready = true
			if (!this._uniqueId) {
				this._uniqueId = nanoid()
			}
		})
	}

	@persist @observable _uniqueId
	@observable config = null
	@observable ready = false

	@observable liveChatId
	@observable meta_domain

	@computed
	get id() {
		// Company Id
		return process.env.REACT_APP_COMPANY_ID
	}

	@computed
	get name() {
		return process.env.REACT_APP_COMPANY_NAME
	}

	@computed
	get logo() {
		return `${process.env.PUBLIC_URL}/assets/${process.env.REACT_APP_COMPANY_HOST === COMPANY.JNP ? COMPANY_PATH.jnp : COMPANY_PATH.jtp}/logo.png?version=${
			process.env.REACT_APP_CI_COMMIT_TAG
		}`
	}

	@computed
	get logos() {
		return {
			logo: `${process.env.PUBLIC_URL}/assets/${process.env.REACT_APP_COMPANY_HOST}/logo.png?version=${process.env.REACT_APP_CI_COMMIT_TAG}`,
			logiMini: `${process.env.PUBLIC_URL}/assets/${process.env.REACT_APP_COMPANY_HOST}/logo-mini.png?version=${process.env.REACT_APP_CI_COMMIT_TAG}`,
		}
	}
	@computed
	get getTheme() {
		return process.env.REACT_APP_COMPANY_HOST === COMPANY.JNP ? THEMES.jnp : process.env.REACT_APP_COMPANY_HOST === COMPANY.OPUS ? THEMES.opus : THEMES.jtp
	}
	@action
	init = async () => {
		await this.fetchFEConfig().catch(console.error)
		await this.fetchAppConfig().catch(console.error)

		setTimeout(() => {
			authStore.verifyToken()
		}, 1000)
	}

	fetchAppConfig = async () => {
		const response = await axios.get(`/company_configs/${this.id}`)

		this.config = response?.data
		this.liveChatId = response.data?.livechatIntegration?.accountId
		this.meta_domain = response?.data?.companyConfigs?.ssrUrl
	}

	fetchFEConfig = async () => {
		const response = await fetch('/app-config.json')
		const data = await response.json()

		return data
	}
}

export const appStore = new AppStore()
