import { gql } from '@apollo/client'
import { JOB_FRAGMENT, WORKER_FRAGMENT, WORKED_TIMESHEET_FRAGMENT } from '../apollo.fragment'

export const GET_CURRENT_WORKER_ASSIGNMENT_QUERY = gql`
	query {
		currentWorkerAssignment {
			id
		}
	}
`

export const GET_WORKER_CURRENT_JOB_QUERY = gql`
	query getWorkerCurrentJobQuery($workerId: String!, $filterScope: FilterAssignmentScopeEnum, $after: String, $before: String, $first: Int, $last: Int) {
		recruiterWorkerAssignments(workerId: $workerId, filterScope: $filterScope, after: $after, before: $before, first: $first, last: $last) {
			nodes {
				id
				status
				createdAt
				updatedAt
				startDate
				endDate
				job {
					...jobFragment
				}
				worker {
					...workerFragment
				}
				workedTimesheets {
					...workedTimesheetFragment
				}
			}
		}
	}

	${JOB_FRAGMENT}
	${WORKER_FRAGMENT}
	${WORKED_TIMESHEET_FRAGMENT}
`

export const GET_WORKER_PAST_JOB_QUERY = gql`
	query getWorkerPastJobQuery($workerId: String!, $filterScope: FilterAssignmentScopeEnum, $after: String, $before: String, $first: Int, $last: Int) {
		recruiterWorkerAssignments(workerId: $workerId, filterScope: $filterScope, after: $after, before: $before, first: $first, last: $last) {
			nodes {
				id
				status
				createdAt
				updatedAt
				startDate
				endDate
				job {
					...jobFragment
				}
				worker {
					...workerFragment
				}
			}
		}
	}

	${JOB_FRAGMENT}
	${WORKER_FRAGMENT}
`

export const GET_JOB_DETAIL_QUERY = gql`
	query getJobDetailQuery($id: String!) {
		workerAssignment(id: $id) {
			id
			status
			createdAt
			updatedAt
			startDate
			endDate
			job {
				...jobFragment
			}
			worker {
				...workerFragment
			}
			workedTimesheets {
				...workedTimesheetFragment
			}
		}
	}

	${JOB_FRAGMENT}
	${WORKER_FRAGMENT}
	${WORKED_TIMESHEET_FRAGMENT}
`

export const LIKE_DISLIKE_JOB_MUTATION = gql`
	mutation LikeOrDislikeAJob($jobId: String!, $action: LikeActionEnum!) {
		likeOrDislikeAJob(jobId: $jobId, action: $action) {
			...jobFragment
		}
	}
	${JOB_FRAGMENT}
`
